import React from 'react';

import Layout from '../../layout/AdminLayout';
import BiddingStatusListView from '../../views/consultant/biddingStatusListView';

const ProjectList = (): React.ReactElement => {
  return (
    <Layout>
      <BiddingStatusListView></BiddingStatusListView>
    </Layout>
  );
};

export default ProjectList;
